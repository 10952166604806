<template>
  <div>
    <b-dropdown-item @click="$bvModal.show('show-hidden-products-modal'+customer.customerId)">Versteckte Produkte / Fallback</b-dropdown-item>
    <b-modal 
      :id="'show-hidden-products-modal'+customer.customerId" 
      :ref="'show-hidden-products-modal'+customer.customerId" 
      :content-class="contentClass"
      hide-footer
      :body-class="bodyClass"
      :header-class="headerClass"
      @hidden="getAllowedProducts()"
      size="lg">
      <template #modal-title>
        Versteckte Produkte anzeigen:<br>
        <small>{{customer.companyProfile.companyName}}</small>
      </template>
      <template>
        <b-form-group>
          <b-checkbox switch v-model="edition3" v-b-tooltip.hover.lefttop :title="edition3 ? 'Wird angezeigt' : 'Wird nicht angezeigt' ">Edition 3</b-checkbox>
        </b-form-group>
        <div class="d-flex flex-end pt-3">
          <submit-button-component v-on:click="submit()" :state="submitButtonState" label="Speichern" :block="false" variant="dark"></submit-button-component>
        </div>
      </template>
    </b-modal>
  
  </div>
</template>

<script>
import { updateDoc, doc, getFirestore} from "firebase/firestore"; 
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";

export default {
  name: "ShowHiddenProducts",
  components: {
    SubmitButtonComponent,
  },
  data() {
    return {
      contentClass: ["border-0", "shadow-lg"],
      bodyClass: ["p-4"],
      headerClass: ["border-0 bg-light px-4"],
      edition3: false,
      isWorking: false,
    }
  },
  props: {
    customer: {
      type: Object,
    },
    docId: {
      type: String,
    }
  },
  watch: {
    customer: {
      handler() {
        this.getAllowedProducts();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  methods: {
    async submit() {
      this.isWorking = true;
      
      const listOfAllowedProducts = []
      if(this.edition3){listOfAllowedProducts.push("edition3")}
       
      updateDoc(doc(getFirestore(), "customers", this.docId), {showHiddenProducts: listOfAllowedProducts})
      .then(() => {
        this.isWorking = false;
        this.$refs['show-hidden-products-modal'+this.customer.customerId].hide();
        this.$emit("refresh")
        this.$store.dispatch("alertSuccess", {
          message: "Fertig",
          show: true,
          noFooter: false,
        })
      })
      .catch((error) => {
          this.isWorking = false;
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });

    },
    getAllowedProducts(){
      this.edition3 = this.customer?.showHiddenProducts && this.customer?.showHiddenProducts.includes("edition3");
    }
  },
  mounted() {
    this.getAllowedProducts();
  },
}
</script>