<template>
  <div
    style="height: 100vh;"
    class="d-flex justify-content-center align-items-center bg-white my-5"
    v-if="isLoading"
  >
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
  <b-card no-body v-else class="rounded-0" >
    <b-card-header class="p-3 d-flex align-items-center justify-content-between bg-light">
      <div class="d-flex align-items-center justify-content-between">
        <b-input-group class="nowrap m-right-20" style="width: 400px;">
          <template #prepend>
            <b-input-group-text class="bg-white pr-0">
              <fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon>
            </b-input-group-text>
          </template>
          <template #append>
            <b-button variant="dark" v-on:click="clearFilter()"><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon></b-button>
          </template>
          <b-input placeholder="Suche" class="border-left-0" v-model="filter" debounce="600"></b-input>
        </b-input-group>
        <div class="d-flex align-items-center">
          <strong class="mr-3">Zeitraum&nbsp;von</strong>
          <b-input-group class="nowrap">
            <template #append>
              <b-button variant="dark" v-on:click="clearStartDate()"><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon></b-button>
            </template>
            <b-form-datepicker v-model="startDate" :min="startDateMin" style="width:160px;" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"></b-form-datepicker>
          </b-input-group>
          <strong class="mx-3">bis</strong>
          <b-input-group class="nowrap mr-1">
            <template #append>
              <b-button variant="dark" v-on:click="clearEndDate()"><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon></b-button>
            </template>
            <b-form-datepicker v-model="endDate" :min="endDateMin" style="width:160px;" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"></b-form-datepicker>
          </b-input-group>
          <div class="d-flex m-right-20">
            <b-button variant="dark" v-on:click="updateDate()"><fa-icon :icon="['fa-regular', 'fa-arrow-rotate-right']" /></b-button>
          </div>
        </div>
      </div>
      <div  class="d-flex">
        <div class="mr-3">
          <export-data-component :exportItems="exportItems"></export-data-component>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="customers.length"
          :per-page="perPage"
          class="mb-0"
        ></b-pagination>
      </div>
    </b-card-header>

    <b-table
      striped 
      ref="table"
      :items="customers"
      :fields="fields"
      class="mb-0"
      :filter="$store.state.filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-compare="sortingChanged"
      sort-icon-left
      :per-page="perPage"
      :current-page="currentPage"
      :no-provider-paging="true"
      :no-provider-sorting="true"
      :no-provider-filtering="true"
      @filtered="onFiltered"
    >
    
      <template #cell(reseller)="data">
        {{ data.item.reseller.companyProfile.companyName }}
      </template>
      <template #cell(renewalQuote)="data">
        {{ data.item.renewalQuote }}%
      </template>
      <template #cell(allocationRate)="data">
        {{ data.item.allocationRate}}%
      </template>
      <template #cell(customer)="data">
        {{ data.item.companyProfile.companyName }}
      </template>
      <template #cell(cotermDate)="data">
        {{ data.item.cotermDate | date }}
      </template>
      <template #cell(creationDate)="data">
        {{ data.item.creationDate | date }}
      </template>
    <template #cell(usedQuantity)="data">
        {{ data.item.usedQuantity }}
      </template>
      <template #cell(activeValue)="data">
        <Currency :value="data.item.activeValue || 0"/>
      </template>
      <template #cell(previousActiveValue)="data">
        <Currency :value="data.item.previousActiveValues?.[year] || 0"/>
      </template>
      <template #cell(renewalValue)="data">
        <Currency :value="data.item.renewalValue || 0"/>
      </template>
      <template #cell(potentialRenewalValue)="data">
        <Currency :value="data.item.potentialRenewalValue || 0"/>
      </template>
      <template #cell(_showDetails)="data">
        <b-button @click="data.toggleDetails" v-show="!data.item._showDetails"><fa-icon :icon="['fa-regular', 'fa-arrow-to-bottom']" class="clickable" /></b-button>
         <b-button @click="data.toggleDetails" v-show="data.item._showDetails"><fa-icon :icon="['fa-regular', 'fa-arrow-from-bottom']" class="clickable"/></b-button>
      </template>
      <template #row-details="row">
        <AnalyticDitail :customerData="row.item" :data="row"/>
      </template>
    </b-table>
    <template #footer>
      <div class="d-flex justify-content-center CustomFooter">
        <div class="d-flex m-3 flex-column align-items-center">
          <small class="text-muted"><b>{{ subscriptionAnalyticActiveNowCount }}</b> Subscriptions Aktiv </small>
          <small class="text-muted"><b>{{ subscriptionAnalyticWillRenewalCount }}</b> Renewals </small>
        </div>
        <div class="d-flex m-3 flex-column align-items-center text-center">
          <small class="m-0"><small  class="text-muted">Renewalquote <br>(geschätzt)</small> <br>{{ Number(renewalQuote).toLocaleString("de-DE") }}%</small>
        </div>
        <div class="d-flex m-3 flex-column align-items-center text-center">
          <small class="m-0"><small  class="text-muted">Zuweisung <br></small> <br>{{ Number(allocationRate).toLocaleString("de-DE") }}%</small>
        </div>
        <div class="d-flex m-3 flex-column align-items-left">
          <small class="text-muted"><b><Currency :value="renewalValue"/></b> Ausgeführtes Renewal </small>
          <small class="text-muted"><b><Currency :value="potentialRenewalValue"/></b> Aktives Renewal </small>
          <hr class="singleLine"/>
          <small class="text-muted"><b><Currency :value="(renewalValue + potentialRenewalValue)"/></b> Renewal Hochrechnung </small>
          <hr class="doubleLines"/>
        </div>
        <div class="d-flex m-3 flex-column align-items-left">
          <small class="text-muted"><b><Currency :value="previousActiveValue"/></b> Aktiv vor Renewal </small>
          <small class="text-muted"><b><Currency :value="activeValue"/></b> Aktive Lizenzen </small>
          <hr class="singleLine"/>
          <small class="text-muted"><b><Currency :value="(previousActiveValue + activeValue)"/></b> BoB</small>
          <hr class="doubleLines"/>
        </div>
      </div>       
    </template>
  </b-card>
</template>

<script>
import moment from "moment-timezone";
import { firestore } from "../plugins/firebase"
import { collection, getDocs, query, getDoc, where, doc } from "firebase/firestore"; 
import ExportDataComponent from '../components/ExportAnalyticDataComponent.vue';
import AnalyticDitail from '../components/AnalyticDetail.vue'
import Currency from '../components/Currency.vue'

export default {
  components: { ExportDataComponent, AnalyticDitail, Currency },
  name: "AnalyticView",
  data() {
    return {
      exportItems: [],
      perPage: 25,
      currentPage: 1,
      firestoreDocFormatSwitchTime: "2024-02-08 16:00:00",
      startDateMin: "2021-07-23",
      startDate: moment().format("YYYY-MM-DD"),
      endDateMin: "2021-07-23",
      endDate: moment().format("YYYY-MM-DD"),
      renewalQuote: 0,
      allocationRate: 0,
      quantity: 0,
      currentAdobeQuarterData: false,
      subscriptionAnalyticWillRenewalCount: 0,
      subscriptionAnalyticActiveNowCount: 0,
      potentialRenewalValue: 0, 
      renewalValue: 0, 
      activeValue: 0,
      dollarValue: 0,
      previousActiveValue: 0,
      filter: this.$store.state.filter,
      isLoading: true,
      customers: [],
      year: new Date().getFullYear(),
      resellers: {},
      products: {},
      renewals: {},
      sortBy: "creationDate",
      sortDesc: true,
      subscriptions: {},
      fields: [
        {
          key: "resellerName",
          label: "Reseller-Name",
          sortable: true,
        },
        {
          key: "customer",
          label: "Endkunde",
          sortable: true,
        },
        {
          key: "cotermDate",
          label: "Anniversary Date",
          sortable: true,
        },
        {
          key: "cancelationDate",
          label: "Cancelation Date",
          sortable: true
        },
        {
          key: "previousActiveValue",
          label: "Aktiv Value vor Renewal",
          sortable: true
        },
        {
          key: "potentialRenewalValue",
          label: "Auto Renewal Value",
          sortable: true,
        },
        {
          key: "activeValue",
          label: "Aktiv Value",
          sortable: true,
        },
        {
          key: "renewalValue",
          label: "Renewal Value",
          sortable: true
        },
        {
          key: "renewalQuote",
          label: "Renewal Quote",
          sortable: true,
        },
        {
          key: "allocationRate",
          label: "Zuweisung",
          sortable: true,
        },
        {
          key: "_showDetails",
          label: "Details",
        },
      ],
    };
  },
  async mounted() {
    this.isLoading = true
    await this.currentAdobeQuarter()
    await this.getProducts();
    await this.getSubscriptions();
    await this.getResellers();
    await this.getData();
  },
  // computed: {
  //   tableHeight() {
  //     return window.innerHeight - 64 - 114 - 75 + "px";
  //   }
  // },
  watch: {
    filter(filter) {
      this.$store.commit("filter", filter);
    },
    customers: {
      async handler(before, after) {
        if (after.length > 0) {
          // this.saveActiveValueToCustomers(after)
        }
      },
      deep: true,
    },
  },
  methods: {
    async updateDate(){
      await this.getData();
      this.$refs.table.refresh();
    },
    async currentAdobeQuarter() {
      const docRef = doc(firestore, "dashboardSettings", "summaryQuarterSettings");
      const document = await getDoc(docRef);
      const docData = document.data();

      if (docData !== null) {
        Object.values(docData.summaryQuarterSettings).forEach((quarterSetting) => {
          if (moment().isBetween(quarterSetting.start, quarterSetting.end)) {
            this.currentAdobeQuarterData = quarterSetting;
            this.startDate = moment(quarterSetting.start, "YYYY-MM-DD").format("YYYY-MM-DD")
            this.endDate = moment(quarterSetting.end, "YYYY-MM-DD").format("YYYY-MM-DD")
          }
        });
      }

      return
    },
    cancelationDate(cotermDate) {
      const momentCotermDate = moment(cotermDate, "YYYY-MM-DD");
      const cotermDateInCurrentYear = moment().format("YYYY") + "-" + momentCotermDate.format("MM-DD");
      const momentCotermDateInCurrentYear = moment(cotermDateInCurrentYear, "YYYY-MM-DD");
      return momentCotermDateInCurrentYear.add(30, "days").format("DD.MM.YYYY");
    },
    clArtNoObject(itemNumber) {
      const itemNumberChunks = itemNumber.split("-");
      const artNrSplittet = itemNumberChunks[3].split("");
      return {
        product: artNrSplittet[0] + artNrSplittet[1] + artNrSplittet[2] + artNrSplittet[3],
        volumeLevel: parseInt(artNrSplittet[4]) + 1,
        language: artNrSplittet[5],
        productType: itemNumberChunks[2],
      };
    },
    sortingChanged(a, b, key, sortDesc) {
      // Special sort allocationRate & renewalQuote
      if (key === 'allocationRate' || key === 'renewalQuote') {
        if(a[key]=== 0){
          if(!sortDesc ){
            return 999999999999
          } else {
            return -999999999999
          }
        }
        return a[key] - b[key];
      }

      // Special sort cancelationDate
      if (key === "cotermDate" || key == "cancelationDate") {
        console.log(a.cancelationDate)
          const dataA = moment(a.cancelationDate, "DD.MM.YYYY").format("X");
          const dataB = moment(b.cancelationDate, "DD.MM.YYYY").format("X");

        return dataA - dataB;
      }

      //ignoriere alle anderen 
      return false;
    },
    // async saveActiveValueToCustomers(customers) {
    //   const promises = []

    //   customers.forEach((customer) => {
    //     const {previousActiveValue, activeValue, customerDocId, cotermDate} = customer;

    //     const momentCotermDate = moment(cotermDate, "YYYY-MM-DD");
    //     const cotermDateInCurrentYear = moment().format("YYYY") + "-" + momentCotermDate.format("MM-DD");
    //     const momentCotermDateInCurrentYear = moment(cotermDateInCurrentYear, "YYYY-MM-DD");

    //     const momentCurrentAdobeQuarterStartDate = moment(this.currentAdobeQuarterData.start, "YYYY-MM-DD");
    //     const momentCurrentAdobeQuarterEndDate = moment(this.currentAdobeQuarterData.end, "YYYY-MM-DD");
    //     const cancelationDate = momentCotermDateInCurrentYear.add(30, "days");
    //     // console.log(momentCurrentAdobeQuarterStartDate, momentCurrentAdobeQuarterEndDate)
    //     if (
    //       previousActiveValue !== activeValue &&
    //       (!cancelationDate.isBetween(momentCurrentAdobeQuarterStartDate, momentCurrentAdobeQuarterEndDate) || moment().isAfter(cancelationDate))
    //     ) {
    //       console.log(moment(cancelationDate).format("YYYY") + " is nicht zwischen " + moment(momentCurrentAdobeQuarterStartDate).format("YYYY") + " und  " + moment(momentCurrentAdobeQuarterEndDate).format("YYYY") )
    //       // console.log("setValue", previousActiveValue, activeValue, customer)
    //       const docRef = doc(firestore, "customers", customerDocId);
    //       const request = updateDoc(docRef, {
    //         previousActiveValue: activeValue,
    //       });
    //       promises.push(request);
    //     }
    //   });

    //   await Promise.all(promises);
    // },
    async getProducts(){
      try {
        const colProductsRef = collection(firestore, "products");
        const ProductsQuery = query(colProductsRef);
        const ProductsSnapshot = await getDocs(ProductsQuery);
        ProductsSnapshot.forEach((doc) => {
          this.products[doc.data().manufacturerItemNumber] = doc.data();
        });
        
      } catch (error) {
        console.error(error);
      }
    },
    async getSubscriptions(){
      try {
        const colSubscriptionsRef = collection(firestore, "subscriptions");
        const subscriptionsQuery = query(colSubscriptionsRef);
        const subscriptionsSnapshot = await getDocs(subscriptionsQuery);
        subscriptionsSnapshot.forEach((doc) => {
          this.subscriptions[doc.id] = doc.data();
          this.subscriptions[doc.id]?.items && this.subscriptions[doc.id].items.forEach(el=>{
            el.productData = this.products[el.offerId]
            el.allocationRate = el.usedQuantity > el.currentQuantity && el.status == 1000 ? "Kaufanforderung" : Number(((el.usedQuantity || 0) / el.currentQuantity) * 100).toFixed(0)
          })
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getResellers(){
      try {
        const colResellersRef = collection(firestore, "resellers");
        const ResellersQuery = query(colResellersRef);
        const ResellersSnapshot = await getDocs(ResellersQuery);
        ResellersSnapshot.forEach((doc) => {
          this.resellers[doc.data().resellerId] = doc.data();
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getData() {
      try {
        this.isLoading = true
        this.customers = []
        this.renewals = {}
        let customerCount = 0;
        let renewalQuote = 0;
        let allocationRate = 0;
        this.subscriptionAnalyticWillRenewalCount = 0;
        this.subscriptionAnalyticActiveNowCount = 0;
        this.potentialRenewalValue = 0;
        this.activeValue = 0;
        this.renewalValue = 0;
        this.previousActiveValue = 0;

        const colRenewalsRef = collection(firestore, "dashboardOrders");
        const renewalsQuery = query(colRenewalsRef, where("creationDate", ">=", new Date(`${moment(moment(this.startDate, "YYYY-MM-DD"), "America/Los_Angeles").format("YYYY/MM/DD")} 00:00:00`)), where("creationDate", "<=", new Date(`${moment(moment(this.endDate, "YYYY-MM-DD"), "America/Los_Angeles").format("YYYY/MM/DD")} 23:59:59`)), where("order.orderType", "==", "RENEWAL" ), where("order.status", "==", "1000" ));
        const renewalsSnapshot = await getDocs(renewalsQuery);
        renewalsSnapshot.forEach((doc) => {
          //speichert renewals nach customer id deswegen darf der Zeitraum nicht über 2 Renewals (also ein Jahr) gebhen!
          this.renewals[doc.data().customer.customerId] = doc.data();
        })
 
        const colCustomersRef = collection(firestore, "customers");
        // const customersQuery = query(colCustomersRef, where("resellerId", "!=", "1000021108"));
        const customersQuery = query(colCustomersRef);
        const customersSnapshot = await getDocs(customersQuery);
        customersSnapshot.forEach((doc) => {
          const customer = doc.data();
          const subscriptionAnalytic = {
            willRenewalCount: 0,
            activeNowCount: 0,
            potentialRenewalValue: 0,
            renewalValue: 0,
            activeValue: 0,
            previousActiveValue: customer.previousActiveValues?.[this.year] || 0,
            usedQuantity: 0,
          };
          if(this.renewals[customer.customerId]){
              const renewalOrder = this.renewals[customer.customerId]
              renewalOrder.order.lineItems.forEach((item) => {

                if(item?.offerId && this.products?.[item?.offerId]?.imputedCost){
                  subscriptionAnalytic.renewalValue += (item.quantity * this.products?.[item?.offerId]?.imputedCost)
                }
              })
              this.customers.push({
                subscriptions: this.subscriptions[customer.customerId].items,
                subscriptionAnalyticWillRenewalCount: subscriptionAnalytic.willRenewalCount,
                subscriptionAnalyticActiveNowCount: subscriptionAnalytic.activeNowCount,
                renewalQuote: (subscriptionAnalytic.activeNowCount > 0 && Number((subscriptionAnalytic.willRenewalCount / subscriptionAnalytic.activeNowCount) * 100).toFixed(2)) || 0,
                allocationRate: (subscriptionAnalytic.activeNowCount > 0 && Number(((subscriptionAnalytic.usedQuantity || 0) / subscriptionAnalytic.activeNowCount) * 100).toFixed(2)) || 0,
                potentialRenewalValue: 0,
                renewalValue: subscriptionAnalytic.renewalValue,
                activeValue: subscriptionAnalytic.activeValue,
                previousActiveValue: subscriptionAnalytic.previousActiveValue || 0,
                usedQuantity: subscriptionAnalytic.usedQuantity,
                realRenewal: true,
                resellerName: this.resellers?.[customer.resellerId].companyProfile.companyName || '',
                customerDocId: doc.id,
                cancelationDate: this.cancelationDate(customer.cotermDate), 
                ...customer
              });
              
              customerCount ++
              renewalQuote += 100
              allocationRate += 100
              this.subscriptionAnalyticWillRenewalCount += subscriptionAnalytic.willRenewalCount
              this.subscriptionAnalyticActiveNowCount += subscriptionAnalytic.activeNowCount;
              this.potentialRenewalValue += subscriptionAnalytic.potentialRenewalValue
              this.renewalValue += subscriptionAnalytic.renewalValue
              this.activeValue += subscriptionAnalytic.activeValue
              this.previousActiveValue += subscriptionAnalytic.previousActiveValue
              this.usedQuantity += subscriptionAnalytic.usedQuantity

          } else if (this.subscriptions[customer.customerId]?.items && moment(customer.cotermDate, "YYYY-MM-DD").isBefore(moment(this.endDate, "YYYY-MM-DD")) && moment(customer.cotermDate, "YYYY-MM-DD").isSameOrAfter(moment(this.startDate, "YYYY-MM-DD"))){

              this.subscriptions[customer.customerId]?.items.forEach((item) => {
                if (item.status == "1000") {
                  if(item.autoRenewal.enabled){ 
                    subscriptionAnalytic.willRenewalCount += (item.autoRenewal?.renewalQuantity || 0);

                    if(item.autoRenewal?.renewalQuantity > 0 && this.products?.[item?.offerId]?.imputedCost) {
                      subscriptionAnalytic.potentialRenewalValue += (item.autoRenewal?.renewalQuantity * this.products?.[item?.offerId]?.imputedCost) || 0;
                    }
                  }

                  const isConsumable = this.products?.[item?.offerId]?.itemNumber && this.clArtNoObject(this.products?.[item?.offerId]?.itemNumber).productType[0] || false

                  subscriptionAnalytic.activeNowCount += (item.currentQuantity || 0);

                  if(item.currentQuantity > 0 && this.products?.[item?.offerId]?.imputedCost) {
                    subscriptionAnalytic.activeValue += (item.currentQuantity * this.products?.[item?.offerId]?.imputedCost) || 0;
                  }
                  
                  if (isConsumable == 6 || isConsumable == 7 || isConsumable == 8) {
                    subscriptionAnalytic.usedQuantity += item.currentQuantity
                  } else {
                    subscriptionAnalytic.usedQuantity += item.usedQuantity || 0
                  }

                }
              });
              this.customers.push({
                subscriptions: this.subscriptions[customer.customerId].items,
                subscriptionAnalyticWillRenewalCount: subscriptionAnalytic.willRenewalCount,
                subscriptionAnalyticActiveNowCount: subscriptionAnalytic.activeNowCount,
                renewalQuote: (subscriptionAnalytic.activeNowCount > 0 && Number((subscriptionAnalytic.willRenewalCount / subscriptionAnalytic.activeNowCount) * 100).toFixed(2)) || 0,
                allocationRate: (subscriptionAnalytic.activeNowCount > 0 && Number(((subscriptionAnalytic.usedQuantity || 0) / subscriptionAnalytic.activeNowCount) * 100).toFixed(2)) || 0,
                potentialRenewalValue: subscriptionAnalytic.potentialRenewalValue,
                activeValue: subscriptionAnalytic.activeValue,
                usedQuantity: subscriptionAnalytic.usedQuantity,
                realRenewal: false,
                resellerName: this.resellers?.[customer.resellerId].companyProfile.companyName || '',
                customerDocId: doc.id,
                cancelationDate: this.cancelationDate(customer.cotermDate),
                ...customer
              });
              
              subscriptionAnalytic.activeNowCount > 0 && customerCount ++
              renewalQuote += Number((subscriptionAnalytic.willRenewalCount / subscriptionAnalytic.activeNowCount) * 100) || 0
              allocationRate += Number(((subscriptionAnalytic.usedQuantity || 0) / subscriptionAnalytic.activeNowCount) * 100) || 0
              this.subscriptionAnalyticWillRenewalCount += subscriptionAnalytic.willRenewalCount
              this.subscriptionAnalyticActiveNowCount += subscriptionAnalytic.activeNowCount;
              this.potentialRenewalValue += subscriptionAnalytic.potentialRenewalValue
              this.previousActiveValue += 0
              this.activeValue += subscriptionAnalytic.activeValue
              this.usedQuantity += subscriptionAnalytic.usedQuantity
            
          }
        });

        this.renewalQuote = (renewalQuote / this.customers.length).toFixed(2);
        this.allocationRate = (allocationRate / this.customers.length).toFixed(2);
        this.exportItems = this.customers;
        this.quantity = this.customers.length;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    clearFilter() {
      this.filter = "";
    },
    clearStartDate() {
      this.startDate = "2021-07-23";
    },
    clearEndDate() {
      this.endDate = moment().format("YYYY-MM-DD");
    },
    onFiltered(filteredItems) {

      this.exportItems = filteredItems;

      this.quantity = filteredItems.length
    }
  }
};
</script>

<style>
.nowrap {
  flex-wrap: nowrap;
}
.m-right-20 {
  margin-right: 20px;
} 
.clickable {
  cursor: pointer;
}
table thead tr { 
  position: sticky !important;
  top: 64px;
  z-index: 501;
  background-color: white;
  border-bottom: 1px solid gray;
}
table td table thead tr {
  position: relative !important;
  z-index: 1;
}
table td .card table {
  margin-bottom: 0px !important;
}
.card table {
  margin-bottom: 114px !important;
}
.card {
  border-bottom: 0px !important;
}
.card-footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: rgba(235, 235, 235, 1) !important;
  z-index: 506;
}
hr.singleLine {
  margin: 0;
  width: 100%;
  height: 2px;
  border-top: 1px solid rgb(166, 166, 166);
}
hr.doubleLines {
  margin: 0;
  width: 100%;
  height: 2px;
  border-top: 1px solid rgb(166, 166, 166);
  border-bottom: 1px solid rgb(166, 166, 166);
}

</style>