<template>
  <div>
    <b-dropdown-item @click="$bvModal.show('discount-modal-'+customer.customerId)">Discount</b-dropdown-item>
    <b-modal 
      :id="'discount-modal-'+customer.customerId" 
      :ref="'discount-modal-'+customer.customerId" 
      :content-class="contentClass"
      hide-footer
      :body-class="bodyClass"
      :header-class="headerClass"
      @hidden="getAllowedProducts()"
      size="lg">
      <template #modal-title>
        Prozentualer Rabatt für:<br>
        <small>{{customer.companyProfile.companyName}}</small>
      </template>
      <template>
        <b-form-group>
          <b-input v-b-tooltip.hover.lefttop :title='`Der Rabatt wird auf alle Produkte für den Kunden \"${customer.companyProfile.companyName}\" angewendet.`' v-model="discount" />
        </b-form-group>
        <div class="d-flex flex-end pt-3">
          <submit-button-component v-on:click="submit()" :state="submitButtonState" label="Speichern" :block="false" variant="dark"></submit-button-component>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { setDoc, getDoc, doc, getFirestore} from "firebase/firestore"; 
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";

export default {
  name: "CustomerDiscount",
  components: {
    SubmitButtonComponent,
  },
  data() {
    return {
      contentClass: ["border-0", "shadow-lg"],
      bodyClass: ["p-4"],
      headerClass: ["border-0 bg-light px-4"],
      isWorking: false,
      discount: 0,
    }
  },
  watch: {
    customer: {
      handler() {
        this.getDiscount()
      },
      deep: true,
      immediate: true,
    },
  },
  props: {
    customer: {
      type: Object,
    },
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  methods: {
    async submit() {
      this.isWorking = true;
      this.discount = this.discount.toString().replace(",", ".");
      if(Number(this.discount) || this.discount == 0 || this.discount == ""){
        const docRef = await doc(getFirestore(), "discounts", this.customer.customerId)
        setDoc(docRef, {discount: Number(this.discount || 0), reseller: this.customer.resellerId})
        .then(() => {
          this.isWorking = false;
          this.$refs['discount-modal-'+this.customer.customerId].hide();
          this.$emit("refresh")
          this.$store.dispatch("alertSuccess", {
            message: "Fertig",
            show: true,
            noFooter: false,
          })
        })
        .catch((error) => {
            this.isWorking = false;
            this.$store.dispatch("alertError", {
              message: error.message,
              show: true,
              noFooter: false,
            })
          });
      } else {
        this.isWorking = false;
        this.$store.dispatch("alertError", {
          message: "Bitte geben Sie eine Zahl ein.",
          show: true,
          noFooter: false,
        })
      }
    },
    async getDiscount(){
      const docRef = await doc(getFirestore(), "discounts", this.customer.customerId)
      getDoc(docRef).then((doc)  =>  {
        const data = doc.data()
        console.log("here", data)
        this.discount = data?.discount || 0;
      })
    }
  },
  async mounted() {
    await this.getDiscount();
  },
}
</script>